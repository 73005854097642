




































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { ExpansionPanelItem } from '@/types/components/expansionpanel';

@Component
export default class ServiceModal extends Vue {
    @Prop({ type: Boolean, required: true }) readonly showDialog!: boolean;
    @Prop({ type: String, required: true }) readonly header!: string;
    @Prop({ type: Array as () => ExpansionPanelItem[], required: true }) readonly expansionPanelItems!: ExpansionPanelItem[];

    private closeDialog() {
        this.$emit('dialogClosed');
    }
}
